import {
  getPortraitImageAttributes,
  getLandscapeImageAttributes,
} from "./utils";

// To be removed after implementing cover image optimisation for all LPs
export interface Input {
  isImageOptimizationEnabled?: boolean;
  acceptHeaders: string;
}

<!-- This file must be kept in sync with <cover-image-preload-link />. -->

$ const { landscapeSrc, landscapeSrcset } = getLandscapeImageAttributes(
  input.acceptHeaders,
);

<if(input.isImageOptimizationEnabled)>
  <picture>
    $ const { portraitSrcset } = getPortraitImageAttributes(
      input.acceptHeaders,
    );
    <source srcset=portraitSrcset media="(max-width: 768px)" sizes="100vw" />
    <source srcset=landscapeSrcset media="(min-width: 768.1px)" sizes="100vw" />
    <img
      src=landscapeSrc
      srcset=landscapeSrcset
      sizes="100vw"
      class="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover object-[40%]"
      loading="eager"
      fetchpriority="high"
      decoding="sync"
      aria-hidden="true"
      alt=""
    />
  </picture>
</if>
<else>
  <img
    src=landscapeSrc
    srcset=landscapeSrcset
    sizes="100vw"
    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover object-[40%]"
    loading="eager"
    fetchpriority="high"
    decoding="sync"
    aria-hidden="true"
    alt=""
  />
</else>
