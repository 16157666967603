//@ts-expect-error: this is an import directive for vite-imagetools.
import fallbackSrcPortrait from "./drive-portrait-1024px.jpg?w=360";
//@ts-expect-error: this is an import directive for vite-imagetools.
import avifSrcSetPortrait from "./drive-portrait-1024px.jpg?w=360;390;410;500;768&format=avif&as=srcset";
//@ts-expect-error: this is an import directive for vite-imagetools.
import webpSrcSetPortrait from "./drive-portrait-1024px.jpg?w=360;390;410;500;768&format=webp&as=srcset";
//@ts-expect-error: this is an import directive for vite-imagetools.
import jpgSrcSetPortrait from "./drive-portrait-1024px.jpg?w=360;390;410;500;768&as=srcset"; // Fallback if client doesn't support avif/webp.
//@ts-expect-error: this is an import directive for vite-imagetools.
import fallbackSrcLandscape from "./drive-landscape-1920px.jpg?w=1080";
//@ts-expect-error: this is an import directive for vite-imagetools.
import avifSrcSetLandscape from "./drive-landscape-1920px.jpg?w=1080;1366;1536;1920&format=avif&as=srcset";
//@ts-expect-error: this is an import directive for vite-imagetools.
import webpSrcSetLandscape from "./drive-landscape-1920px.jpg?w=1080;1366;1536;1920&format=webp&as=srcset";
//@ts-expect-error: this is an import directive for vite-imagetools.
import jpgSrcSetLandscape from "./drive-landscape-1920px.jpg?w=1080;1366;1536;1920&as=srcset"; // Fallback if client doesn't support avif/webp.

// The image widths above are approximated from stats from https://gs.statcounter.com/screen-resolution-stats.

// The srcset strings, from vite-imagetools, will look like:
// /@imagetools/7db0b224e007fda202ed7d87c0505196b3ba0cc0 500w, /@imagetools/f8d425b86815f81e7ebc3f8754ab567fce9f84a3 1460w, ... etc.
export function getPortraitImageAttributes(acceptHeaders: string | null) {
  const supportsAvif = acceptHeaders?.includes("image/avif");
  const supportsWebp = acceptHeaders?.includes("image/webp");

  return {
    portraitSrc: fallbackSrcPortrait,
    portraitSrcset: supportsAvif
      ? avifSrcSetPortrait
      : supportsWebp
        ? webpSrcSetPortrait
        : jpgSrcSetPortrait,
  };
}

export function getLandscapeImageAttributes(acceptHeaders: string | null) {
  const supportsAvif = acceptHeaders?.includes("image/avif");
  const supportsWebp = acceptHeaders?.includes("image/webp");

  return {
    landscapeSrc: fallbackSrcLandscape,
    landscapeSrcset: supportsAvif
      ? avifSrcSetLandscape
      : supportsWebp
        ? webpSrcSetLandscape
        : jpgSrcSetLandscape,
  };
}
